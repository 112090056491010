<template>
  <div v-if="isPopupVisible" class="popup-overlay">
    <div class="popup">
      <div class="popup-header">
        <h3>弹窗标题</h3>
        <button @click="closePopup">关闭</button>
      </div>
      <div class="popup-body">
        <!-- 弹窗内容 -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomPopup',
  data() {
    return {
      isPopupVisible: false
    };
  },
  methods: {
    closePopup() {
      this.isPopupVisible = false;
    }
  }
};
</script>

<style>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.popup {
  background-color: white;
  width: 100%;
  border-radius: 15px 15px 0 0;
  padding: 20px;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-body {
  margin-top: 20px;
}
</style>
