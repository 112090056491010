<template>
  <div id="app">
    <div class="big">
      <div class="boxone">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tuone.png">
      </div>
      <div class="boxtwo" @click="advancedvip">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tutwo.png">
        <img class="vipadvanced" src="http://oss.kexuemihe.com/viph5/vipadvanced.gif">
      </div>
      <div class="boxtwo" @click="ptvip">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tuthree.png">
        <img class="vipadvanced" src="http://oss.kexuemihe.com/viph5/vipadvanced.gif">
      </div>
      <div class="boxtwo">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tufour.png">
      </div>
      <div class="boxtwo">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tuflve.png">
      </div>
      <div class="boxtwo">
        <img class="images" src="http://oss.kexuemihe.com/viph5/tusix.png">
      </div>
      <div style="width: 100%;height: 20px;">

      </div>
      <div class="bottombutton">
        <img style="width: 30%;height: auto;" src="http://oss.kexuemihe.com/viph5/598.png">
        <img style="width: 30%;height: auto;" src="http://oss.kexuemihe.com/viph5/298.png">
      </div>
    </div>
    <bottom-popup v-if="isPopupVisible" @close="isPopupVisible = false" />
  </div>
</template>
<script>
import BottomPopup from './components/BottomPopup.vue';

export default {
  name: 'App',
  components: {
    BottomPopup
  },
  data() {
    return {
      isPopupVisible: true
    };
  },
  mounted() {
    
    window.parent.postMessage({ type: 'h5PageStatus', canGoBack: true }, '*');
  },

  methods: {
    showPopup() {
      this.isPopupVisible = true;
    },
    ptvip() {
      
      var messageData = {
        content: '这是从H5页面中发送的消息'
      }

      var sendData = JSON.stringify(messageData)
      window.open.postMessage(sendData, '*')
      console.log('我是123vip');
    },
    advancedvip() {

      const message = { data: '我是高级vip' };
      window.postMessage(message, '*');
      console.log('我是高级vip');
    }
  }

}
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  overflow: auto;
  /* 其他样式 */
}

.big {
  width: 100%;
  height: 100%;

  background-color: #F21F25;
  overflow: auto;

  .boxone {
    width: 100%;
  }

  .boxtwo {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
  }

}

.images {
  width: 100%;
  height: auto;
}

.vipadvanced {
  width: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}

.bottombutton {
  width: 100%;

  position: absolute;
  bottom: 20px;

  display: flex;
  justify-content: space-around;
}</style>
