import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
// 计算并设置根元素的字体大小
function setRem() {
  const rem = document.documentElement.clientWidth / 10;
  document.documentElement.style.fontSize = rem + 'px';
}

// 初始化时调用一次
setRem();

// 监听窗口大小变化时重新计算
window.addEventListener('resize', setRem);

new Vue({
  render: h => h(App),
}).$mount('#app')
